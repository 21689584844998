<template>
  <div class="mt-3 mb-4 pics">
    <img
      v-for="(item, ind) in banners"
      :key="ind"
      @click="productDescription"
      class="col-6 col-lg-2 p-0"
      :src="item.image"
      alt=""
    />
    <!--earlier was added class to image: "col-md-4" -->
  </div>
</template>

<script>
export default {
  props: {
    banners: {
      type: Array,
      required: true
    }
  },
  methods: {
    productDescription() {
      this.$router.replace("/products");
    },
  },
}
</script>

<style scoped>
.block4 .pics {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.block4 .pics img {
  flex: 1 1 auto;
  object-fit: contain;
}
</style>
